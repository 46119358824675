import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import DashboardService from "../../services/DashboardService";
import "./ModalFilter.scss";

interface ModalFilterProps {
  show: boolean;
  onClose?: any;
  setSelectFilterSetor?: any;
  setSelectFilterDtConclusao?: any;
  setSelectFilterRecurso?: any;
}

const ModalFilter = ({
  show,
  onClose,
  setSelectFilterSetor,
  setSelectFilterDtConclusao,
  setSelectFilterRecurso,
}: ModalFilterProps) => {
  const [modal, setModal] = useState<boolean>(false);

  const [filterSetor, setFilterSetor] = useState<boolean>(false);
  const [filterRecurso, setFilterRecurso] = useState<boolean>(false);
  const [filterDtConclusao, setFilterDtConclusao] = useState<boolean>(false);

  const [selectedFilterSetor, setSelectedFilterSetor] = useState<string>("");
  const [selectedFilterRecurso, setSelectedFilterRecurso] =
    useState<string>("");
  const [selectedFilterDtConclusao, setSelectedFilterDtConclusao] =
    useState<string>("");

  const [occupations, setOccupations] = useState([]);
  const [resources, setResources] = useState([]);

  useEffect(() => {
    if (show) {
      setModal(show);
    }
  }, [show]);

  const toggle = () => setModal(!modal);
  const handleOnClose = () => onClose(false);

  useEffect(() => {
    setSelectFilterSetor(filterSetor ? selectedFilterSetor : "");
    setSelectFilterRecurso(filterRecurso ? selectedFilterRecurso : "");
    setSelectFilterDtConclusao(
      filterDtConclusao ? selectedFilterDtConclusao : ""
    );
  }, [
    selectedFilterSetor,
    selectedFilterDtConclusao,
    selectedFilterRecurso,
    filterSetor,
    filterDtConclusao,
    filterRecurso,
  ]);

  const getOccupations = () => {
    DashboardService.listAllOccupations().then((data) => {
      const [_Response, _Error] = data;

      if (_Response != undefined && !_Error) {
        setOccupations(_Response);
      }
    });
  };

  const getResources = () => {
    DashboardService.listAllResources().then((data) => {
      const [_Response, _Error] = data;

      if (_Response != undefined && !_Error) {
        setResources(_Response);
      }
    });
  };

  useEffect(() => {
    if (!occupations.length) {
      getOccupations();
    }
    if (!resources.length) {
        getResources();
    }
  }, []);

  return (
    <Modal
      centered
      scrollable
      size="lg"
      isOpen={modal}
      toggle={toggle}
      onClosed={handleOnClose}
      className="ModalFilter"
    >
      <ModalHeader>
        <h1>Filtros</h1>
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value="setor"
                id="filterSetor"
                checked={filterSetor}
                onChange={(e) => setFilterSetor(e.target.checked)}
              />
              <label className="form-check-label">Setor</label>
              {filterSetor && (
                <div className="w-50">
                  <select
                    id="selectFilterSetor"
                    className="form-control"
                    onChange={(e) => setSelectedFilterSetor(e.target.value)}
                    value={selectedFilterSetor}
                  >
                    <option value="" selected>
                      Todos
                    </option>
                    {occupations.length &&
                      occupations?.map((o: any) => {
                        return (
                          <option value={o} selected>
                            {o}
                          </option>
                        );
                      })}
                  </select>
                </div>
              )}
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value="recurso"
                id="filterRecurso"
                checked={filterRecurso}
                onChange={(e) => setFilterRecurso(e.target.checked)}
              />
              <label className="form-check-label">Recursos</label>
              {filterRecurso && (
                <div className="w-50">
                  <select
                    id="selectFilterRecursos"
                    className="form-control"
                    onChange={(e) => setSelectedFilterRecurso(e.target.value)}
                    value={selectedFilterRecurso}
                  >
                    <option value="" selected>
                      Todos
                    </option>
                    {resources.length &&
                      resources?.map((o: any) => {
                        return (
                          <option value={o} selected>
                            {o}
                          </option>
                        );
                      })}
                  </select>
                </div>
              )}
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value="dtConclusao"
                id="filterDtConclusao"
                checked={filterDtConclusao}
                onChange={(e) => setFilterDtConclusao(e.target.checked)}
              />
              <label className="form-check-label">Data Conclusão</label>
              {filterDtConclusao && (
                <div className="w-50">
                  <input
                    className="form-control"
                    type="date"
                    value={selectedFilterDtConclusao}
                    onChange={(e) =>
                      setSelectedFilterDtConclusao(e.target.value)
                    }
                  />
                </div>
              )}
            </div>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button type="button" onClick={toggle}>
          <span>Fechar</span>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalFilter;
