import { FC, useEffect, useState } from "react";
import PlansService from "../../services/PlansService";
import "./UsersTable.scss";
import DataTable from "react-data-table-component";
import Status from "../../enums/Status";
import Loading from "../Loading/Loading";
import Plans from "../../enums/Plans";
import moment from "moment";

type User = {
  uniqueId: string;
  id: number;
  nomeRazaoSocial: string;
  nomeFantasia: string;
  cpfCnpj: string;
  email: string;
  dataNascimentoAbertura: string;
  ocupacao: string;
  tipoPessoa: string;
  telefone: string;
  celular: string;
  status: number;
  dataCriacao: string;
  horaCriacao: string;
  sendWhats: number;
  idCliente: number;
  cliente: string;
  idPlano: number;
  dataConclusaoJornada: string;
  piorRecurso: string;
};

const UsersTable: FC<any> = ({
  filter = "",
  selectFilterDtConclusao,
  selectFilterSetor,
  selectFilterRecurso
}) => {
  const [users, setUsers] = useState<User[] | null>(null);
  const [isLoading, setLoading] = useState<boolean>(false);

  const getPlans = async () => {
    setLoading(true);
    const _Response = await PlansService.listAll();
    setUsers(_Response);
    setLoading(false);
  };

  useEffect(() => {
    getPlans();
  }, []);

  const columns = [
    {
      name: "Usuário",
      selector: (row: User) => row.nomeRazaoSocial,
    },
    {
      name: "E-mail",
      selector: (row: User) => row.email,
    },
    {
      name: "Ocupação",
      selector: (row: User) => row.ocupacao,
    },
    {
      name: "Cliente",
      selector: (row: User) => row.cliente,
    },
    {
      name: "Plano",
      selector: (row: User) => Plans[row.idPlano],
    },
    {
      name: "Conclusão Jornada",
      selector: (row: User) => row.dataConclusaoJornada,
    },
    {
      name: "Pior Recurso",
      selector: (row: User) => row.piorRecurso,
    },
    {
      name: "Status",
      selector: (row: User) => Status[row.status].toUpperCase(),
      conditionalCellStyles: [
        {
          when: (row: User) => row.status == 1,
          style: {
            color: "#99DA35",
            fontWeight: "bold",
          },
        },
        {
          when: (row: User) => row.status == 0,
          style: {
            color: "#ff0000",
            fontWeight: "bold",
          },
        },
      ],
    },
  ];

  const filteredItems = users?.filter((item) => {
    let filtered = JSON.stringify(item).toLowerCase().indexOf(filter.toLowerCase()) !== -1;

    if(selectFilterSetor != "" && item.ocupacao != selectFilterSetor) {
      filtered = false;
    }
    
    if(selectFilterRecurso != "" && item.piorRecurso != selectFilterRecurso) {
      filtered = false;
    }

    if(selectFilterDtConclusao != "") {
      if(item?.dataConclusaoJornada){
        if(item?.dataConclusaoJornada != moment(selectFilterDtConclusao).format("DD/MM/YYYY")) {
          filtered = false; 
        } 
      }else { 
        filtered = false; 
      }
    } 

    return filtered;
  });

  return (
    <div className="UsersTable">
      <div className="header-plans text-center">
        <h1>Usuários</h1>
      </div>

      {isLoading && <Loading />}

      {users && (
        <DataTable
          responsive
          highlightOnHover
          pagination
          columns={columns}
          data={filteredItems ? filteredItems : []}
          keyField="uniqueId"
        />
      )}
    </div>
  );
};

export default UsersTable;
